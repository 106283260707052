import React from "react";

const Footer: React.FC = () => (
  <div className="footer">
    <span>
      All rights reserved.
    </span>
  </div>
);

export default Footer;
