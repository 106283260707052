import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import configureSentry from "settings/sentry";

import "assets/scss/app.scss";
import "translations/i18n";

import App from "./App";

configureSentry();

ReactDOM.render(<App />, document.getElementById("root"));

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js");
}
