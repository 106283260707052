import * as Sentry from "@sentry/browser";

/**
 * Applies configuration for Sentry.
 */
const configureSentry = (): void => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: "",
    });

    Sentry.configureScope(scope => {
      scope.setTag("app", "frontend");
    });
  }
};

export default configureSentry;
