import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Router } from "react-router-dom";

import CurrentUserContainer from "contexts/currentUser/CurrentUserContainer";
import ErrorBoundary from "components/ErrorBoundary";
import apolloClient from "settings/apolloClient";
import AppRouter from "components/AppRouter";
import history from "settings/history";

const App: React.FC = () => (
  <ErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <CurrentUserContainer>
        <Router history={history}>
          <AppRouter />
        </Router>
      </CurrentUserContainer>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
