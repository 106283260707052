import React from "react";

const Header: React.FC = () => (
  <div className="header">
    <h1>
      Jungsoft
    </h1>
  </div>
);

export default Header;
