import React from "react";
import { Helmet } from "react-helmet";

import { useRouteProps } from "contexts/route";
import Header from "components/Header";
import Footer from "components/Footer";
import i18n from "translations/i18n";

/**
 * App wrapper. Can be used to render Header, Footer, etc.
 * @param param0 options
 */
const AppWrapper: React.FC = ({
  children,
}) => {
  const {
    showHeader,
    showFooter,
  } = useRouteProps();

  const htmlAttributes = {
    lang: i18n.language,
  };

  return (
    <div className="app-wrapper">
      <Helmet htmlAttributes={htmlAttributes} />

      {
        showHeader && (
          <Header />
        )
      }

      {children}

      {
        showFooter && (
          <Footer />
        )
      }
    </div>
  );
};

export default AppWrapper;
