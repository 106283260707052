import { Cookies } from "react-cookie";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "@apollo/client/cache";
import { createLink } from "apollo-v3-absinthe-upload-link";
import { buildSentryErrorLink } from "apollo-sentry-helper";

import { TOKEN_KEY } from "constants/auth";

const uri = process.env.NODE_ENV === "production"
  ? `${window.location.protocol}//${window.location.hostname}/graphql`
  : "http://localhost:4000/graphql";

const httpLink = createLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_KEY, { path: "/" });

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const sentryErrorLink = buildSentryErrorLink();

const link = ApolloLink.from([
  sentryErrorLink,
  authLink,
  httpLink,
]);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default apolloClient;
