import React from "react";
import { matchPath, useHistory } from "react-router-dom";

import appRoutes from "routes/appRoutes";
import { useCurrentUser } from "contexts/currentUser";
import { ROOT_PAGE_PATH } from "routes";

const RouteSessionValidator: React.FC = ({
  children,
}) => {
  const history = useHistory();

  const [currentUser, {
    loading: loadingCurrentUser,
  }] = useCurrentUser();

  const pathname = history?.location?.pathname ?? "";

  const appRoute = appRoutes.find((route) => matchPath(pathname, route));

  const isInvalidRoute = !!(
    appRoute?.requireAuthentication
    && !loadingCurrentUser
    && !currentUser
  );

  if (appRoute?.requireAuthentication && loadingCurrentUser) {
    return null;
  }

  if (isInvalidRoute) {
    window.location.href = ROOT_PAGE_PATH;
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default RouteSessionValidator;
