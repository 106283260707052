import React from "react";
import { useTranslation } from "react-i18next";

import logo from "assets/images/logo.png";

const Home: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://jungsoft.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("home.title")}
        </a>
      </header>
    </div>
  );
};

export default Home;
