import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Creates an unverified user with unconfirmed email */
  createUnverifiedUser: Maybe<Scalars['String']>;
  /** Logins an user */
  loginUser: Maybe<Scalars['String']>;
  /** Sends an email to reset password */
  requestResetPassword: Maybe<Scalars['String']>;
  /** Resets the user's password */
  resetPassword: Maybe<Scalars['String']>;
  /** Sends an email for user email verification */
  sendVerificationEmail: Maybe<User>;
};


export type RootMutationTypeCreateUnverifiedUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Gets the user associated with the given token */
  currentUser: Maybe<User>;
  /** Gets a user by id */
  getUser: Maybe<User>;
  /** Gets a list of all users */
  listUsers: Maybe<Array<Maybe<User>>>;
  /** Gets the application version */
  version: Maybe<Scalars['String']>;
};


export type RootQueryTypeGetUserArgs = {
  id: Scalars['Int'];
};

/** An user */
export type User = {
  __typename?: 'User';
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  role: Maybe<UserRoleEnum>;
  verified: Maybe<Scalars['Boolean']>;
};

/** User roles */
export enum UserRoleEnum {
  /** Admin */
  Admin = 'ADMIN',
  /** User */
  User = 'USER'
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);


export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;